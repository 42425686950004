import React from 'react'
import PropTypes from 'prop-types'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import CardWrapper from '../CardWrapper/dynamic'
import ThresholdStyled from './styled'

class ThresholdComponent extends React.Component {
  renderTooltip(detectLevel, unit) {
    return (
      <div className="detectLevel-tooltip">
        <div className="value">{detectLevel}</div>
        <div className="unit">{unit}</div>
      </div>
    )
  }

  renderBlocks() {
    const levels = ['low', 'normal', 'high']
    const blocks = []
    const { maxLevel, detectLevel, unit, colors } = this.props
    const valuePerBlock = maxLevel / 15

    for (let i = 14; i >= 0; i -= 1) {
      const isDetectLevelLessThanCurrentBlock = detectLevel <= i * valuePerBlock
      const [color, level] = isDetectLevelLessThanCurrentBlock ? ['', ''] : [colors[Math.floor(i / 5)], levels[Math.floor(i / 5)]]
      blocks.push(
        <div key={`block-${i}`} className={`block ${level}`} style={{ background: color }}>
          {this.renderTooltip(detectLevel, unit)}
        </div>,
      )
    }

    return (
      <div className="block-wrapper" id={`${this.props.id}-block-wrapper`} data-test={`${this.props.id}-block-wrapper`}>
        {blocks}
      </div>
    )
  }

  renderTrafficLevel() {
    let output = this.renderBlocks()
    if (this.props.loading) {
      output = <SkeletonAnimation id="traffic-threshold-skeleton-loading" className="loading" />
    }
    return (
      <CardWrapper
        id={`${this.props.id}-card-wrapper`}
        data-test={`${this.props.id}-card-wrapper`}
        className="threshold-card-wrapper"
        title={this.props.title}
      >
        <div className="detectLevel-wrapper flex justify-center">{output}</div>
      </CardWrapper>
    )
  }

  render() {
    return (
      <ThresholdStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        {this.renderTrafficLevel()}
      </ThresholdStyled>
    )
  }
}

ThresholdComponent.defaultProps = {
  id: 'threshold-component',
  title: 'Traffic Threshold',
  detectLevel: 1000,
  maxLevel: 2000,
  loading: false,
}

ThresholdComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  detectLevel: PropTypes.number.isRequired,
  maxLevel: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  unit: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
}

export default ThresholdComponent
