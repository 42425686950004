import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  .threshold-card-wrapper {
    .card-title {
      justify-content: center;
      padding: 0;
      padding-left: 0;
    }
    .detectLevel-wrapper {
      width: 100%;
      height: 100%;
      padding: 40px 40px 26px 40px;
      .block-wrapper {
        width: 80px;
        display: grid;
        grid-row-gap: 4px;
        grid-template-rows: repeat(15, 1fr);
        position: relative;
        .block {
          width: 100%;
          height: 19px;
          background-color: #1a1a1a;
          box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
          position: relative;
          &.low:hover,
          &.normal:hover,
          &.high:hover {
            .detectLevel-tooltip {
              visibility: visible;
            }
          }
          .detectLevel-tooltip {
            z-index: 1;
            font-family: Prompt;
            font-size: 12px;
            visibility: hidden;
            position: absolute;
            left: 8px;
            top: -55px;
            width: 82px;
            height: 48px;
            border-radius: 2px;
            border-radius: 2px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            border: solid 1px #e4eeea;
            background-color: #1e1e1e;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 8px 7px;
            color: white;
            transition: 0s;
            .value {
              width: 69px;
              height: 12px;
              font-family: Prompt;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
            }
            .unit {
              width: 72px;
              height: 15px;
              font-family: Prompt;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
            }
          }
          &.high {
            background-color: #660c0d;
          }
          &.normal {
            background-color: #981214;
          }
          &.low {
            background-color: #cc7809;
          }
        }
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeL}) and (max-width: ${device.maxSizeL}) {
    .threshold-card-wrapper {
      .detectLevel-wrapper {
        padding: 20px 30px;
        .block-wrapper {
          width: 56px;
          grid-row-gap: 3px;
          .block {
            height: 14px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .threshold-card-wrapper {
      .detectLevel-wrapper {
        padding: 20px 40px;
        .block-wrapper {
          width: 56px;
          grid-row-gap: 4px;
          .block {
            height: 16px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${device.maxSizeS}) {
    .threshold-card-wrapper {
      .detectLevel-wrapper {
        padding: 32px 28%;
        .block-wrapper {
          width: 100%;
          grid-row-gap: 4px;
          .block {
            height: 18px;
          }
        }
      }
    }
  }
`
